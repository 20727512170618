import React, { Component } from 'react';

// Components
import Header from '../components/Headers/Header';
import GridBets from '../components/Grids/GridBets';
import ModalForm from '../components/Modals/ModalForm';

class IndexPage extends Component {
    constructor() {
        super()

        this.onClickToggle = this.onClickToggle.bind(this);
    }

    state = {
        isModalVisible: false
    }

    onClickToggle(e) {
        e.preventDefault();

        this.setState({
            isModalVisible: !this.state.isModalVisible
        })
    }

    render() {
        return (
            <>
                <Header
                    onClickToggle={(e) => {
                        e.persist();
                        this.onClickToggle(e);
                    }}
                />
                <GridBets />
                <ModalForm
                    isVisible={this.state.isModalVisible}
                    onClickToggle={(e) => {
                        e.persist();
                        this.onClickToggle(e);
                    }}
                />
            </>
        );
    }
}

export default IndexPage
