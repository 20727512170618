import React, { Component } from 'react';

class CardCurrentBet extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const currentMonth = new Date(this.props.date).getUTCMonth() + 1;

        return (
            <div className="bg-green-600 py-3 px-4 rounded-lg ring-red-100 shadow-lg antialiased">
                <div className="flex">
                    <svg width="24" height="24" className="text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p className="pl-2 font-medium text-white">Votre pari</p>
                </div>
                {this.props.date && <div className="flex mt-1 items-center">
                    <svg width="24" height="24" className="text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p className="pl-2 text-sm text-white">{`${new Date(this.props.date).getUTCDate()}/${currentMonth.toString().padStart(2, '0')}/${new Date(this.props.date).getUTCFullYear()}`}</p>
                </div>}
                {this.props.content && <div className="flex flex-row mt-1">
                    <div className="text-white">
                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div className="pl-2 mt-1">
                        <p className="text-sm text-white">- {`${this.props.content.sexe.charAt(0).toUpperCase()}${this.props.content.sexe.slice(1)}`}</p>
                        <p className="text-sm text-white">- {`${this.props.content.prenom} ou ${this.props.content.prenom_bonus}`}</p>
                        <p className="text-sm text-white">- {`${this.props.content.poids} kilos`}</p>
                        <p className="text-sm text-white">- {`${this.props.content.taille} centimètres`}</p>
                    </div>
                </div>}
            </div>
        );
    }
}

export default CardCurrentBet
