import React, { Component } from 'react';

// Stores
import BetStore from '../../stores/BetStore';

// Libs
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class ModalForm extends Component {
    constructor(props) {
        super(props)

        this.onSubmit = this.onSubmit.bind(this);
        this.onClickClose = this.onClickClose.bind(this);
        this.onSendError = this.onSendError.bind(this);
        this.onSendSuccess = this.onSendSuccess.bind(this);

        // Refs
        this.modalRef = React.createRef();
        this.formRef = React.createRef();
    }

    state = {
        isSent: false,
        isErrored: false
    }

    componentDidUpdate() {
        this.checkScroll();
    }

    onSubmit(e) {
        e.preventDefault();

        const formData = new FormData(this.formRef.current);

        const objectToSend = {};
        formData.forEach((value, key) => objectToSend[key] = value);

        // Storing date
        objectToSend['date'] = Date.now();

        BetStore.addBetToDatabase(objectToSend, this.onSendError, this.onSendSuccess);
    }

    onClickClose(e) {
        if (this.formRef && this.formRef.current) {
            this.formRef.current.reset();
        }

        this.setState({
            isSent: false,
            isErrored: false
        })

        this.props.onClickToggle(e);
    }

    onSendError() {
        this.formRef.current.reset();

        this.setState({
            isSent: false,
            isErrored: true
        })
    }

    onSendSuccess() {
        this.setState({
            isSent: true,
            isErrored: false
        })
    }

    checkScroll() {
        if (this.props.isVisible) {
            disableBodyScroll(this.modalRef.current);
        } else {
            clearAllBodyScrollLocks();
        }
    }

    render() {
        return (
            <div className={`fixed h-screen inset-0 ${this.props.isVisible ? '' : 'hidden'}`}>
                <button onClick={this.props.onClickToggle} className="appearance-none absolute inset-0 bg-gray-500 bg-opacity-60 w-screen"></button>
                <div className="w-12/12 sm:8/12 xl:w-5/12 flex h-full justify-center items-center relative z-10 mx-auto p-3">
                    <div className="overflow-scroll overscroll-auto bg-white rounded-lg shadow-lg max-h-full w-full px-4 pt-4 pb-20 sm:p-8 relative" ref={this.modalRef}>
                        <button className="appearance-none  absolute p-4 top-0 right-0" onClick={(e) => { this.onClickClose(e); }}>
                            <svg className="current-color text-gray-500" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <h4 className="text-xl font-bold text-indigo-700">Je veux parier</h4>
                        {!this.state.isErrored && !this.state.isSent && <form className="flex flex-col mt-4 pt-4 w-10/12 border-t border-indigo-100" onSubmit={(e) => { e.persist(); this.onSubmit(e); }} ref={this.formRef}>
                            <p className="font-bold mb-3">Vous êtes qui ?</p>
                            <div className="flex flex-col w-full">
                                <label className="text-sm font-medium mb-1 text-gray-500 text-gray-500" htmlFor="user_prenom">Votre prénom<sup>*</sup></label>
                                <input type="text" className="border-2 border-indigo-200 px-3 py-2 rounded-md" name="user_prenom" id="user_prenom" placeholder="Votre prénom" required />
                            </div>
                            <div className="flex flex-col w-full mt-4">
                                <label className="text-sm font-medium mb-1 text-gray-500" htmlFor="user_email">Votre e-mail<sup>*</sup></label>
                                <input type="email" className="border-2 border-indigo-200 px-3 py-2 rounded-md" name="user_email" id="user_email" placeholder="Votre email" required />
                            </div>

                            <p className="font-bold mt-7 pt-4 mb-3 border-t border-indigo-100">Votre pari</p>
                            <div className="flex flex-col w-full">
                                <span className="text-sm font-medium mb-1 text-gray-500">Sexe<sup>*</sup></span>
                                <div className="flex">
                                    <div className="flex mr-4 items-center">
                                        <input type="radio" className="border-2 border-indigo-200 px-3 py-2 rounded-md" id="sexe_fille" name="sexe" value="fille" required />
                                        <label className="text-sm ml-2" htmlFor="sexe_fille">Fille</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input type="radio" className="border-2 border-indigo-200 px-3 py-2 rounded-md" id="sexe_garcon" name="sexe" value="garcon" />
                                        <label className="text-sm ml-2" htmlFor="sexe_garcon">Garçon</label>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-4">
                                <div className="flex flex-col">
                                    <label className="text-sm font-medium mb-1 text-gray-500" htmlFor="prenom">Prénom<sup>*</sup></label>
                                    <input type="text" className="border-2 border-indigo-200 px-3 py-2 rounded-md" name="prenom" id="prenom" placeholder="Prénom" required />
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-sm font-medium mb-1 text-gray-500" htmlFor="prenom_bonus">Prénom bonus (sexe opposé)<sup>*</sup></label>
                                    <input type="text" className="border-2 border-indigo-200 px-3 py-2 rounded-md" name="prenom_bonus" id="prenom_bonus" placeholder="Prénom bonus (sexe opposé)" required />
                                </div>
                            </div>
                            <div className="flex flex-col w-full mt-4">
                                <label className="text-sm font-medium mb-1 text-gray-500" htmlFor="birth_date">Date de naissance<sup>*</sup></label>
                                <input type="date" className="border-2 border-indigo-200 px-3 py-2 rounded-md" name="birth_date" id="birth_date" placeholder="Date de naissance" required />
                            </div>
                            <div className="flex flex-col w-full mt-4">
                                <label className="text-sm font-medium mb-1 text-gray-500" htmlFor="poids">Poids (en kg)<sup>*</sup></label>
                                <input type="number" inputmode="decimal" className="border-2 border-indigo-200 px-3 py-2 rounded-md" min="0" max="10" step="0.001" name="poids" id="poids" placeholder="Poids (en kg)" required />
                            </div>
                            <div className="flex flex-col w-full mt-4">
                                <label className="text-sm font-medium mb-1 text-gray-500" htmlFor="taille">Taille (en cm)<sup>*</sup></label>
                                <input type="number" inputmode="decimal" className="border-2 border-indigo-200 px-3 py-2 rounded-md" min="0" max="100" step="1" name="taille" id="taille" placeholder="Taille (en cm)" required />
                            </div>
                            <div className="flex flex-col w-full mt-4">
                                <label className="text-sm font-medium mb-1 text-gray-500" htmlFor="taille">Couleur de la peau<sup>*</sup></label>
                                <input type="text" className="border-2 border-indigo-200 px-3 py-2 rounded-md" name="peau_couleur" id="peau_couleur" placeholder="Couleur de la peau" required />
                            </div>
                            <div className="flex flex-row w-full mt-8">
                                <button type="submit" className="antialiased focus:outline-none text-base px-6 py-2 rounded-md font-semibold text-white bg-indigo-500">Envoyer</button>
                                <button className="text-sm ml-4" type="reset">
                                    <span className="text-red-500 border-b border-red-500">Annuler</span>
                                </button>
                            </div>
                        </form>}
                        {!this.state.isErrored && this.state.isSent && <div>
                            <div className="flex flex-col mt-4 pt-4 w-10/12 border-t border-indigo-100">
                                <p className="text-lg font-bold">Bien joué c'est envoyé.</p>
                                <p className="text-sm">Vous pouvez fermer la fenêtre.</p>
                            </div>
                        </div>}
                        {this.state.isErrored && !this.state.isSent && <div>
                            <div className="flex flex-col mt-4 pt-4 w-10/12 border-t border-indigo-100">
                                <p className="text-lg font-bold">Apparemment, il y a eu une erreur.</p>
                                <p className="text-sm">Il faudrait réessayer plus tard.</p>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalForm
