import React, { Component } from 'react';

// Stores
import BetStore from '../../stores/BetStore';

class Header extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <header className="relative">
                <div className="max-w-7xl mx-auto px-4 sm:px-6">
                    <div className="grid grid-cols-12 gap-3 items-center py-4 sm:py-6 border-b-2 border-gray-100">
                        <h1 className="font-bold text-xl sm:text-2xl text-indigo-700">Parions</h1>
                        <button onClick={this.props.onClickToggle} className={`${BetStore.getUserBet() ? 'pointer-events-none cursor-not-allowed opacity-50' : ''} text-white bg-indigo-500 antialiased focus:outline-none text-base px-3 py-2 rounded-md font-semibold ring col-start-6 sm:col-start-10 col-end-13 place-self-end`} disabled={BetStore.getUserBet()}>
                            {!BetStore.getUserBet() && <>Je veux parier</>}
                            {BetStore.getUserBet() && <>Vous avez déjà parié :(</>}
                        </button>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header
