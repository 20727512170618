import React, { Component } from 'react';

// Components
import CardBet from '../Cards/CardBet';
import CardCurrentBet from '../Cards/CardCurrentBet';

// Stores
import BetStore from '../../stores/BetStore';

class GridBets extends Component {
    constructor(props) {
        super(props);

        // ES6 Rebind
        this.onValues = this.onValues.bind(this);
    }

    state = {
        bets: null,
        isLoaded: false
    }

    componentDidMount() {
        BetStore.getBets(this.onValues)
    }

    onValues(values) {
        this.setState({
            bets: values,
            isLoaded: true
        });
    }

    getCardsFromBets() {
        const userBetID = BetStore.getUserBet();

        const Bets = Object.entries(this.state.bets).map((bet, index) => {
            const betID = bet[0],
                betContent = bet[1];

            if (userBetID && betID === userBetID) {
                return (
                    <CardCurrentBet
                        key={`bet-${index}`}
                        date={betContent.date ? betContent.date : null}
                        name={betContent.user_prenom}
                        content={betContent}
                    />
                )
            } else {
                return (
                    <CardBet
                        key={`bet-${index}`}
                        date={betContent.date ? betContent.date : null}
                        name={betContent.user_prenom}
                    />
                )
            }
        })


        return Bets;
    }

    render() {
        let CardBets = null;

        if (this.state.bets) {
            CardBets = this.getCardsFromBets();
        }

        return (
            <section className="relative">
                <div className="max-w-7xl mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center py-4 sm:pt-6 sm:pb-3 md:justify-start md:space-x-10">
                        <h2 className="text-base sm:text-lg font-medium">Qui a déjà parié ?</h2>
                    </div>
                    {this.state.bets && this.state.isLoaded && <div className="grid sm:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-4 sm:py-6">
                        {CardBets}
                    </div>}
                    {!this.state.bets && !this.state.isLoaded && <p className="text-sm">Chargement des paris déjà placés…</p>}
                    {!this.state.bets && this.state.isLoaded && <p className="text-sm">Aucun pari placé pour le moment ! Allez-y !</p>}
                </div>
            </section>
        );
    }
}

export default GridBets
